import { Component } from "react"
import { Container ,Grid, Typography} from '@material-ui/core';
import { withThemeProps } from "@findep/microfronts-core"
import PropTypes from 'prop-types'
// FINANCIERA COMPONENTS
import {  CardElevation } from '@findep/mf-landings-core'
//ICONS MATERIAL UI


    
 
class AvisoPriCmp extends Component {    

    render() {
        const {            
            titleIcon=<span><strong>Apoyo Financiero, Inc. Políticas de privacidad</strong><br/></span>,
            txtTitleEmp='Última actualización y vigencia: 7 de septiembre de 2022',
            txtTitle=<span><br/>Apoyo Financiero, Inc. se compromete a proteger la privacidad y seguridad de su información personal. A continuación encontrará información sobre sus derechos de privacidad y cómo se recopila, utiliza y protege su información. Para obtener detalles adicionales, revise estas políticas para conocer cómo recopilamos, divulgamos y usamos la información que recabamos o recibimos sobre usted.<br/></span>,
            txtCnt=<span><a href="/es/terminos-condiciones?option=privacy-notice" target="_blank" rel="nofollow noopener noreferrer" style={{color:"rgb(105, 141, 33)"}}><strong>Aviso de Privacidad:</strong></a> Comprenda cómo Apoyo Financiero, Inc. recopila, usa y divulga información y cómo protegemos la privacidad y seguridad de su información personal. Nuestra política de privacidad se aplica a todos los clientes, solicitantes, usuarios de aplicaciones móviles y visitantes del sitio web.<br/></span>,
            txtCntOne=<span><a href="/es/terminos-condiciones?option=privacy-notice-california" target="_blank" rel="nofollow noopener noreferrer" style={{color:"rgb(105, 141, 33)"}}><strong>Aviso para los residentes de California:</strong></a> Obtenga más información sobre sus Derechos de Privacidad de California y nuestro Aviso de recopilación de información personal (cómo Apoyo Financiero, Inc. recopila, usa y divulga información personal relacionada con los residentes de California cubiertos por la Ley de Privacidad del Consumidor de California ("CCPA ”)).<br/></span>,
            txtCntTwo=<span><a href="/es/terminos-condiciones?option=privacy-notice-tx" target="_blank" rel="nofollow noopener noreferrer" style={{color:"rgb(105, 141, 33)"}}><strong>Aviso de Privacidad:</strong></a> Residentes de Texas<br/></span>,
            txtCntThree=<span><a href="/es/terminos-condiciones?option=privacy-notice-az" target="_blank" rel="nofollow noopener noreferrer" style={{color:"rgb(105, 141, 33)"}}><strong>Aviso de Privacidad:</strong></a> Residentes de Arizona<br/></span>,        
            txtCntFour=<span>Cómo contactarnos: Si tiene alguna pregunta sobre nuestras políticas de privacidad, contáctenos:<br/></span>,
            txtCntFive=<span><strong>Apoyo Financiero, Inc.<br/>
                                2300 Clayton Rd Suite 1170<br/>
                                Concordia, CA 94520<br/>
                                o<br/>
                                <a href="mailto:afi@apoyofin.com" target="_blank" rel="nofollow noopener noreferrer" style={{color:"rgb(230, 103, 40)"}}>afi@apoyofin.com</a> o al <a href="tel:18008912778" target="_parent" rel="nofollow noopener noreferrer" style={{color:"rgb(230, 103, 40)"}}>1-800-891-2778</a><br/></strong>
                        </span>
            } = this.props
        return (
            <Container justify="center">
                <br/><br/>
                <Grid container justify="left">
                    <Grid item style={{ textAlign: "left" }}>
                        <Typography component='h3' variant="h4" >
                        <strong style={{color:'#698d21'}}>{titleIcon}</strong>                        
                        </Typography>
                        <Typography component='h3' variant="h6" >
                            <strong style={{color:'#e66728'}}>{txtTitleEmp}</strong>                        
                        </Typography>
                        
                    </Grid>
                </Grid>
                <br/><br/>
               <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <CardElevation>
                    <Typography component='subtitle' variant="subtitle" >
                        {txtTitle}                       
                    </Typography>
                    <br/>
                    <Typography component='subtitle' variant="subtitle" >
                        {txtCnt}                       
                    </Typography>
                    <br/>
                    <Typography component='subtitle' variant="subtitle" >
                        {txtCntOne}                       
                    </Typography>
                    <br/>
                     <Typography component='subtitle' variant="subtitle" >
                        {txtCntTwo}                       
                    </Typography>
                    <br/>
                     <Typography component='subtitle' variant="subtitle" >
                        {txtCntThree}                       
                    </Typography>
                    <br/>
                     <Typography component='subtitle' variant="subtitle" >
                        {txtCntFour}                       
                    </Typography>
                    <br/>
                     <Typography component='subtitle' variant="subtitle" >
                        {txtCntFive}                       
                    </Typography>                      
                    </CardElevation>
                    <br/><br/>
                    
                </Grid>
                
                </Grid>
            </Container>

        )
    }
}

AvisoPriCmp.propTypes = {      
    titleIcon: PropTypes.string, 
    txtTitle: PropTypes.string,     
    txtTitleEmp: PropTypes.string,
    txtCnt: PropTypes.string,
    txtCntOne: PropTypes.string,
    txtCntTwo: PropTypes.string,
    txtCntThree: PropTypes.string,
    txtCntFour: PropTypes.string,
    txtCntFive: PropTypes.string
}

export default withThemeProps(AvisoPriCmp, 'VDPNAvisoPri')






